<template>
  <div>
    <ParallaxImageTitle :imagePath="require('@/assets/images/www/programs/ra-aus-flight-instructor-course-800.png')" title="RA-AUS FLIGHT INSTRUCTOR COURSE" subtitle="Teach what you love" />
    <div class="program-description">
      <h1>What is the Flight Instructor Course?</h1>
      <p>
        Our RA-Aus instructor course is conducted by our Chief Flying Instructor Rod Flockhart who says instructors are the teachers of good habits!       </p>
      <br>

      <h1>Requirements</h1>
      <div class="list-container">
        <ul class="program-list">
          <li>RA-Aus Pilot Certificate</li>
          <li>100 hours PIC of RA-Aus aircraft</li>
          <li>Radio Operator</li>
          <li>Passenger Carrying</li>
          <li>Cross Country</li>
          <li>Human Factors</li>
          <li>Undertake a Principles of Methods of Instruction Course</li>
        </ul>
      </div>

      <br>

      <h1>The course requires:</h1>
      20 hours of dual in-flight instruction <br>
      30 hours of ground theory instruction <br>

      <br>
    </div>
  </div>
</template>

<script>
import ParallaxImageTitle from '@/components/ParallaxImageTitle.vue'

export default {
  components: {
    ParallaxImageTitle
  }
}
</script>

<style lang="scss" scoped>
@import '@/styles/_presets.scss';
@include program-page;
</style>
